

import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../IconButton";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { ButtonColors, ButtonVariants } from "../../Button/Button.types";
import { ToggleButtonDiv } from '../MediaPanel/StyledMediaPanel';

function AddButton({onClick}: any) {
    return (
        <ToggleButtonDiv>
            <IconButton variant={ButtonVariants.text} color={ButtonColors.tertiary} onClick={onClick}>
                <FontAwesomeIcon icon={faPlus} title='Toggle view from text container to attachments menu'/>
            </IconButton>
        </ToggleButtonDiv>
    )
}

export default AddButton

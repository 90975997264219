import React, { useRef, useState } from 'react'
import { useLanguageContext } from "../../LanguageProvider";
import SendButton from "../../SendButton";
import AddButton from "./AddButton";
import { InputRow, SHOW_TEXT_ROW, StyledTexContainer, TextArea, } from "../styledTextContainer";
import { translatePlaceholderText } from './utils';
import { InputPanelProps } from './InputPanel.types';
import Menu from '../../Menu/Menu';
import { useTextContainerContext } from '../TextContainerProvider';
import { isMobile } from 'react-device-detect'
import useAutosizeTextArea from '../../WBBTextContainer/useAutosizeTextArea';
import { useClientSettings } from "../../ClientSettingsProvider/ClientSettingsProvider";

function InputPanel({ value, textNodeRef, onChange, onKeyDown, onToggleMediaPanel, onSend,  isPortal, disabled}: InputPanelProps) {
    const { lang } = useLanguageContext();
    const placeholder = translatePlaceholderText(lang)
    const timerRef = useRef< null | ReturnType<typeof setTimeout>>(null);
    const { zoomLevel } = useClientSettings()
    const { showMediaPanelBtn, textAreaRef, persistentMenu, onClickPersistentMenuItem, msgsContainerRef } = useTextContainerContext()
    const resetAutoSize = useAutosizeTextArea(textAreaRef, msgsContainerRef, value, timerRef, zoomLevel)

    const [focused, setFocused] = useState(false)

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const handleSend = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {  
        if (value.trim() !== '') {
            onSend(event)
        }
        resetAutoSize()
    }

    return (
        <InputRow ref={textNodeRef} className={SHOW_TEXT_ROW}>
            {showMediaPanelBtn && <AddButton onClick={onToggleMediaPanel} disabled={disabled}/>}
            <StyledTexContainer focused={focused}>
                <TextArea mobile={isMobile} disabled={disabled} ref={textAreaRef} placeholder={placeholder} value={value} onChange={onChange} onKeyDown={onKeyDown} onFocus={onFocus} onBlur={onBlur}/>
                <SendButton onClick={handleSend} isPortal={isPortal} disabled={disabled}/>
            </StyledTexContainer>
            {persistentMenu?.length && (
                <Menu parentElement={textNodeRef.current} elements={persistentMenu}
                    onClickItem={onClickPersistentMenuItem}
                />
            )}
        </InputRow>
    )
}

export default InputPanel;

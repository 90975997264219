import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../IconButton";
import { faChevronsLeft } from '@fortawesome/pro-light-svg-icons';
import { ButtonColors, ButtonVariants } from "../../Button/Button.types";
import { MediaPanelProps } from "./MediaPanel.types";
import { ToggleButtonDiv } from "./StyledMediaPanel";

function ToggleBackButton({ onClick }: {onClick: MediaPanelProps['onToggle']}) {
    return (
        <ToggleButtonDiv>
            <IconButton variant={ButtonVariants.text} color={ButtonColors.tertiary} onClick={onClick}>
                <FontAwesomeIcon icon={faChevronsLeft} title="Toggle view from attachments menu to text container" />
            </IconButton>
        </ToggleButtonDiv>
    )
}

export default ToggleBackButton;

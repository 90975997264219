import styled from 'styled-components';

export const StyledScrollContainer = styled.div<{width?: string}>`
    padding: 10px;
    padding-top:0px;
    padding-bottom: 5px;
    overflow-y: auto;
    height: 100%;
    width: ${({ width }) => width ? width : "auto" };
    overflow-x: hidden;
    scrollbar-width: thin !important;
    scrollbar-color:  ${({ theme }) => theme.colors.neutral.outline} ${({ theme }) => theme.colors.background.main};
    &::-webkit-scrollbar {
     width: 5px;
   }
   &::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.colors.background.main}; 
     -webkit-border-radius: 10px;
     border-radius: 10px;
   }
   /* Handle */
   &::-webkit-scrollbar-thumb {
     -webkit-border-radius: 10px;
     border-radius: 10px;
     background: ${({ theme }) => theme.colors.background.outline};
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
   }

   
`;
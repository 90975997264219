import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import React, { useState, useLayoutEffect } from 'react'
import { Div, MapWrapper } from "./StyledLocationMap";
import Button from "../Button";
import { Icon, LatLngTuple } from "leaflet";
import { LocationFormType } from "../OverlapProvider/OverlapProvider.types";


const DEFAULT_POSITION = {
    lat: 51.5072178,
    lng: -0.1275862
}

function LocationMarker({ position, onMove }: {position: LatLngTuple, onMove: (coord: LatLngTuple) => void}) {
    const map = useMap();

    useLayoutEffect(() => {
        map.locate().on("locationfound", function (e) {
            onMove([e.latlng.lat, e.latlng.lng])
            map.flyTo(e.latlng, map.getZoom());
            const radius = e.accuracy;
            const circle = L.circle(e.latlng, radius);
            circle.addTo(map);
        });
    }, [map]);

    return (
        <Marker
            position={position}
            draggable
            autoPan
            icon={new Icon({iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png', iconSize: [25, 41], iconAnchor: [12, 41]})}
            eventHandlers={{
                moveend: (event) => {
                    onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
                }
            }}
        />
    );
}


function LocationMap(props: LocationFormType & {onSaveLocation: (text: string) => void}) {
    const [markerPos, setMarkerPos] = useState<LatLngTuple>([51.507, -0.12]);

    const handleSaveLocation = () => {
        props.onSaveLocation(`${markerPos[0]}, ${markerPos[1]}`)
    }

    const onMove = (coordinates: LatLngTuple) => {
        setMarkerPos(coordinates)
    }

    return (
        <Div>
            <MapWrapper>
                <MapContainer center={DEFAULT_POSITION} zoom={13} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarker onMove={onMove} position={markerPos} />
                </MapContainer>
            </MapWrapper>
            <Button text={`${props.selectButtonPrompt[props.lang] || 'Save Location'}`} onClick={handleSaveLocation} />
        </Div>
    )
}

export default LocationMap


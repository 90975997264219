import { useCallback, useEffect } from "react";

type Timeout =React.MutableRefObject<null | ReturnType<typeof setTimeout>>
const TEXTAREA_CONTAINER_HEIGHT = 140;
const TEXTAREA_HEIGHT = 35;
const MAX_SCROLL_HEIGHT = 300;
const useAutosizeTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement> | undefined,
  msgsContainerRef: React.RefObject<HTMLDivElement> | undefined,
  value: string,
  timerRef: Timeout,
  zoomLevel?: number,
  isWidget=true
) => {

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (textAreaRef?.current) {
        textAreaRef.current.style.height = "0px";
        let scrollHeight = textAreaRef.current.scrollHeight < MAX_SCROLL_HEIGHT ? textAreaRef.current.scrollHeight : MAX_SCROLL_HEIGHT;
        if(isWidget && scrollHeight - 10 < TEXTAREA_HEIGHT) {
          scrollHeight -= 10;
        }
        if(isWidget && scrollHeight >= 294) {
          textAreaRef.current.style.height = 294 + "px";
        } else {
          textAreaRef.current.style.height = scrollHeight + "px";
        }       
        if (msgsContainerRef?.current) {
          let heightDiff = TEXTAREA_CONTAINER_HEIGHT + scrollHeight - TEXTAREA_HEIGHT;
          if((value?.length === 0 && heightDiff <=156) || (heightDiff <=156 && scrollHeight < TEXTAREA_HEIGHT)){
            heightDiff = 156
            if ( zoomLevel && zoomLevel >= 2 ) { 
              heightDiff = 128;
            }
          }
          msgsContainerRef.current.style.height = `calc(100% - ${heightDiff}px)`;
     
          const divElement = document.getElementById('wbb-unique-scrollbar');
          if (divElement) {
            divElement.scrollTop = divElement.scrollHeight;
          }
        }
      }
    }, 150);
    return () => {
     if(timerRef.current) clearTimeout(timerRef.current);
    };
  }, [textAreaRef, msgsContainerRef, value, timerRef, zoomLevel ]);
  

  const resetAutoSize = useCallback(
    () => {
      if (msgsContainerRef?.current) {
        msgsContainerRef.current.style.height = `calc(100% - ${TEXTAREA_CONTAINER_HEIGHT}px)`
      }
      if (textAreaRef?.current) {
        textAreaRef.current.style.height = TEXTAREA_HEIGHT + "px";
      }
    },
    [textAreaRef, msgsContainerRef],
  )

  return resetAutoSize
};

export default useAutosizeTextArea;

import styled, { css, keyframes } from "styled-components";

export const HIDE_TEXT_ROW = 'hide-text-row'
export const SHOW_MEDIA_ROW = 'show-media-row'
export const SHOW_TEXT_ROW = 'show-text-row'
export const HIDE_MEDIA_ROW = 'hide-media-row'
export const MEDIA_ROW = 'media-row'
export const TEXT_ROW = 'text-row'

export const Container = styled.div<{ fullScreen?: boolean }>`
    min-height: 52px;
    width: ${({ theme }) => theme.additionalStyles.width || '430px'};
    ${({ fullScreen }) => fullScreen && css`
        width: 100%;
    `}
    `;

export const InputDiv = styled.div<{ left?: boolean, fullScreen?: boolean }>`
    min-height: 52px;
    // border: 1px solid ${({ theme }) => theme.colors.primary.main};
    border-top: none;
    border-radius: ${({ theme, left, fullScreen }) => fullScreen ? '0px' : left ? ` 0 0  ${theme.additionalStyles.bordersRadius.main} 0` :
        ` 0 0 0 ${theme.additionalStyles.bordersRadius.main}`};
    display: inline-flex;
    width: -webkit-fill-available;
    display: block;
    align-items: center;
    `;

export const Divider = styled.hr`
    border: none;
    border-top: 0.5px solid ${({ theme }) => theme.colors.neutral.outline};
    height: 0;
    position: relative;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    width: calc(100% - 16px);
    z-index: ${({ theme }) => theme.additionalStyles.zIndex + 1};
    margin-left: 8px;
    margin-right: 8px;
    opacity: 85%;
    `

export const DividerDiv = styled.div`
border: 1px solid ${({ theme }) => theme.colors.primary.main};
border-top: none;
border-bottom: none;
border-radius: ${({ theme }) =>
        ` 0 0 0 ${theme.additionalStyles.bordersRadius.main}`};
`;
export const StyledTexContainer = styled.div<{ focused?: boolean }>`
    width: 100%;
    display: flex;
    border: none;
    font-family: inherit;
    background-color: ${({ theme }) => theme.colors.neutral.main};
    border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
    min-height: 40px;
    align-items: center;
    padding: 0px 6px 0px 0px;
    margin: 6px;
    ${({ focused }) => focused && css`
        border:1px solid ${({ theme }) => theme.colors.primary.main};
    `}
`;

const revealTextRow = keyframes`
from {
    clip-path: inset(0 0 0 100%);
}
to {
    clip-path: inset(0 0 0 0);
}
`

const revalMediaRow = keyframes`
from {
    clip-path: inset(0 100% 0 0);
}
to {
  clip-path: inset(0 0 0 0);
}
`

export const TransitionElementsWrapper = styled.div`
width: inherit;
.${SHOW_TEXT_ROW} {
    display: inline-flex;
    width: inherit;
    display: flex;
    align-items: center;
    animation: ${revealTextRow} 0.5s forwards;
}
.${HIDE_TEXT_ROW}{
    display: none;
}
.${SHOW_MEDIA_ROW}{
    animation: ${revalMediaRow} 0.5s forwards;
}
.${HIDE_MEDIA_ROW}{
    display: none;
  }
.${MEDIA_ROW}{
    display: inline-flex;
    width: inherit;
    display: flex;
    align-items: center;s
    }
.${TEXT_ROW}{
    display: inline-flex;
    width: inherit;
    display: flex;
    align-items: center;
    }
}
`

export const InputRow = styled.div`
display: inline-flex;
display: flex;
align-items: center;
`


export const TextArea = styled.textarea<{mobile?:boolean}>`
    width: inherit;
    min-height: 38px;
    margin: 0;
    background: transparent;
    resize: none;
    border-radius: ${({ theme }) => theme.additionalStyles.bordersRadius.main};
    overflow: hidden;
    box-sizing: border-box;
    padding: 7px 0px 6px 6px;
    border: none;
    font-family: inherit;
    line-height: 1.3;
    background-color: ${({ theme }) => theme.colors.neutral.main};
    font-size: ${({mobile})=>mobile?'16px':'14px'};
    &:focus {
        outline: none !important;
        // border:1px solid ${({ theme }) => theme.colors.primary.main};
    }
`;
